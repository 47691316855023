import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  TextField,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Container,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import TopMenu from './TopMenu';

function InserirVenda() {
  const steps = [
    'WhatsApp do Cliente',      // Etapa 0
    'Dados do Processo',        // Etapa 1
    'Informações do Veículo',   // Etapa 2
    'Pagamento',                // Etapa 3
    'Finalizar'                 // Etapa 4
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [venda, setVenda] = useState({
    whatsapp: '',
    processo: '',
    nome: '',
    entrada: '',
    cpf: '',
    modelo: '',
    ano: '',
    valor_venda: '',
    marca_veiculo: '',
    bem_estar: '',
    forma_pagamento: '',
    tipo_pagamento: '',
    segunda_parcela: '',
    data_segunda: null,
    terceira_parcela: '',
    data_terceira: null,
    data_venda: null,
    consultor: ''
  });
  const [temSegundaParcela, setTemSegundaParcela] = useState(false);
  const [temTerceiraParcela, setTemTerceiraParcela] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Carregar o nome do consultor do localStorage
  useEffect(() => {
    const consultorName = localStorage.getItem('username') || 'Consultor';
    setVenda((prevState) => ({ ...prevState, consultor: consultorName }));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVenda((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  // Função para buscar dados pelo WhatsApp
  const handleBuscarDados = async () => {
    const numeroTelefone = venda.whatsapp.trim();

    if (!numeroTelefone) {
      setError('Por favor, insira um número de WhatsApp válido.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await axios.get(`https://g.wh.tec.br/buscarTelefone/${numeroTelefone}`);

      if (response.data && response.data.resultadosMySQL.length > 0) {
        const dados = response.data.resultadosMySQL[0];
        setVenda((prevState) => ({
          ...prevState,
          processo: dados.processo || '',
          nome: dados.nome || '',
          entrada: dados.entrada || '',
          cpf: dados.cpf || '',
          modelo: dados.modelo || '',
          ano: dados.ano || '',
          // Preencha outros campos conforme necessário
        }));
        handleNext(); // Avança para a próxima etapa
      } else {
        setError('Nenhum dado encontrado para o número de WhatsApp fornecido.');
      }
    } catch (err) {
      console.error('Erro ao buscar dados:', err);
      setError('Ocorreu um erro ao buscar os dados. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');

      // Convertendo datas para o formato YYYY-MM-DD antes de enviar
      const formattedVenda = {
        ...venda,
        data_segunda: venda.data_segunda ? venda.data_segunda.toISOString().split('T')[0] : null,
        data_terceira: venda.data_terceira ? venda.data_terceira.toISOString().split('T')[0] : null,
        data_venda: venda.data_venda ? venda.data_venda.toISOString().split('T')[0] : null
      };

      await axios.post('https://sert.wh.tec.br/inserir-processo/', formattedVenda, {
        headers: {
          Authorization: token
        }
      });
      alert('Venda inserida com sucesso!');
      // Opcional: Redirecionar ou resetar o formulário
    } catch (error) {
      console.error('Erro ao inserir venda:', error);
      alert('Ocorreu um erro ao inserir a venda.');
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Insira o número de WhatsApp do cliente:
            </Typography>
            <TextField
              name="whatsapp"
              label="WhatsApp do Cliente"
              value={venda.whatsapp}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
            <Button
              variant="contained"
              color="primary"
              onClick={handleBuscarDados}
              disabled={loading}
              fullWidth
              sx={{ mb: 2 }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Buscar Dados'}
            </Button>
          </>
        );
      case 1:
        return (
          <>
            <TextField
              name="processo"
              label="Número do Processo"
              value={venda.processo}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
            <TextField
              name="nome"
              label="Nome do Cliente"
              value={venda.nome}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
            <TextField
              name="entrada"
              label="Valor de Entrada"
              value={venda.entrada}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
            <TextField
              name="cpf"
              label="CPF do Cliente"
              value={venda.cpf}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
          </>
        );
      case 2:
        return (
          <>
            <TextField
              name="marca_veiculo"
              label="Marca do Veículo"
              value={venda.marca_veiculo}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              name="modelo"
              label="Modelo do Veículo"
              value={venda.modelo}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              name="ano"
              label="Ano do Veículo"
              value={venda.ano}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
          </>
        );
      case 3:
        return (
          <>
            <TextField
              name="valor_venda"
              label="Valor da Venda"
              value={venda.valor_venda}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            
            {/* Campo de Seleção para Tipo de Pagamento */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="tipo-pagamento-label">Tipo de Pagamento</InputLabel>
              <Select
                labelId="tipo-pagamento-label"
                id="tipo-pagamento"
                name="tipo_pagamento"
                value={venda.tipo_pagamento}
                label="Tipo de Pagamento"
                onChange={handleInputChange}
              >
                <MenuItem value="A vista">À Vista</MenuItem>
                <MenuItem value="Parcelado">Parcelado</MenuItem>
              </Select>
            </FormControl>

            {/* Campo de Seleção para Forma de Pagamento */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="forma-pagamento-label">Meio de Pagamento</InputLabel>
              <Select
                labelId="forma-pagamento-label"
                id="forma-pagamento"
                name="forma_pagamento"
                value={venda.forma_pagamento}
                label="Meio de Pagamento"
                onChange={handleInputChange}
              >
                <MenuItem value="Pix">Pix</MenuItem>
                <MenuItem value="Boleto">Boleto</MenuItem>
                <MenuItem value="Cartão de Crédito">Cartão de Crédito</MenuItem>
                <MenuItem value="Dinheiro">Dinheiro</MenuItem>
                <MenuItem value="Débito">Débito</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={temSegundaParcela}
                  onChange={(e) => setTemSegundaParcela(e.target.checked)}
                />
              }
              label="Tem segunda parcela?"
              sx={{ mb: 2 }}
            />
            {temSegundaParcela && (
              <>
                <TextField
                  name="segunda_parcela"
                  label="Valor da Segunda Parcela"
                  value={venda.segunda_parcela}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <DatePicker
                  label="Data da Segunda Parcela"
                  value={venda.data_segunda}
                  onChange={(newValue) => setVenda((prevState) => ({ ...prevState, data_segunda: newValue }))}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth sx={{ mb: 2 }} />
                  )}
                />
              </>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={temTerceiraParcela}
                  onChange={(e) => setTemTerceiraParcela(e.target.checked)}
                />
              }
              label="Tem terceira parcela?"
              sx={{ mb: 2 }}
            />
            {temTerceiraParcela && (
              <>
                <TextField
                  name="terceira_parcela"
                  label="Valor da Terceira Parcela"
                  value={venda.terceira_parcela}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <DatePicker
                  label="Data da Terceira Parcela"
                  value={venda.data_terceira}
                  onChange={(newValue) => setVenda((prevState) => ({ ...prevState, data_terceira: newValue }))}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth sx={{ mb: 2 }} />
                  )}
                />
              </>
            )}
          </>
        );
      case 4:
        return (
          <>
            <TextField
              name="consultor"
              label="Consultor"
              value={venda.consultor}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              sx={{
                mb: 2,
                backgroundColor: '#f5f5f5',
                borderRadius: '8px',
              }}
              variant="outlined"
            />
            <DatePicker
              label="Data da Venda"
              value={venda.data_venda}
              onChange={(newValue) => setVenda((prevState) => ({ ...prevState, data_venda: newValue }))}
              renderInput={(params) => (
                <TextField {...params} fullWidth sx={{ mb: 2 }} />
              )}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
              sx={{
                mt: 2,
                padding: '12px',
                borderRadius: '8px'
              }}
            >
              Finalizar e Enviar
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ minHeight: '100vh', backgroundColor: '#fff', padding: '20px 0' }}>
        <TopMenu />
        <Container maxWidth="sm" sx={{ mt: 4 }}>
          <Typography
            variant="h4"
            sx={{
              color: '#333',
              textAlign: 'center',
              mb: 4,
              fontWeight: 'bold'
            }}
          >
            📝 Inserir Venda
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 4, p: 3, backgroundColor: '#f7f7f7', borderRadius: '12px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)' }}>
            {renderStepContent(activeStep)}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button
              variant="contained"
              color="secondary"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{
                backgroundColor: '#ff3b30',
                color: '#fff',
                '&:hover': { backgroundColor: '#e01e0f' },
                width: '45%'
              }}
            >
              Voltar
            </Button>
            {activeStep < steps.length - 1 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                sx={{
                  backgroundColor: '#34c759',
                  color: '#fff',
                  '&:hover': { backgroundColor: '#28a745' },
                  width: '45%'
                }}
              >
                Próximo
              </Button>
            )}
          </Box>
        </Container>
      </Box>
    </LocalizationProvider>
  );
}

export default InserirVenda;
