// PaginaInicial.js

import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    Modal,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Pagination,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Fab,
    ToggleButton,
    ToggleButtonGroup
} from '@mui/material';
import TopMenu from './TopMenu';
import LeadCard from './LeadCard';
import KanbanBoard from './KanbanBoard';
import SearchIcon from '@mui/icons-material/Search';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import AddIcon from '@mui/icons-material/Add';

function PaginaInicial() {
    const [leads, setLeads] = useState([]);
    const [selectedLead, setSelectedLead] = useState(null);
    const [open, setOpen] = useState(false);
    const [notificationOpen] = useState(false);
    const [visibleLeads, setVisibleLeads] = useState(0);
    const [shouldHideNextDayLeads] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const leadsPerPage = 9;
    const [searchOpen, setSearchOpen] = useState(false);
    const [nomeCliente, setNomeCliente] = useState('');
    const [statusLead, setStatusLead] = useState('');
    const [telefone, setTelefone] = useState('');
    const [timers, setTimers] = useState({});

    const [viewMode, setViewMode] = useState('grid');
    const [statuses, setStatuses] = useState([]);
    const [addStatusOpen, setAddStatusOpen] = useState(false);
    const [newStatus, setNewStatus] = useState('');

    useEffect(() => {
        const storedStatuses = localStorage.getItem('leadStatuses');
        if (storedStatuses) {
            setStatuses(JSON.parse(storedStatuses));
        } else {
            const defaultStatuses = [
                "enviado",
                "negociando",
                "nao responde",
                "venda realizada",
                "resolveu com o banco",
                "fechou com outra assessoria",
                "nao e a pessoa"
            ];
            setStatuses(defaultStatuses);
            localStorage.setItem('leadStatuses', JSON.stringify(defaultStatuses));
        }
    }, []);

    const handleAddStatus = (statusToAdd) => {
        const updatedStatuses = [...statuses, statusToAdd];
        setStatuses(updatedStatuses);
        localStorage.setItem('leadStatuses', JSON.stringify(updatedStatuses));
    };

    const fetchLeads = async (date) => {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        const formattedDate = date ? date.toISOString().split('T')[0] : null;

        let url = 'https://apil2.wh.tec.br/base_sp/';
        if (formattedDate) {
            url += `?date=${formattedDate}`;
        }

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'consultor-name': username
                }
            });

            if (response.ok) {
                const data = await response.json();
                if (data.length === 0) {
                    fetchOldLeads();
                } else {
                    setLeads(data);
                    const countVisibleLeads = data.reduce((count, lead) => {
                        return count + (lead.status && lead.status !== '' ? 1 : 0);
                    }, 0);
                    setVisibleLeads(countVisibleLeads);
                }
            } else {
                console.error('Falha ao buscar leads.');
            }
        } catch (error) {
            console.error('Erro ao tentar buscar leads:', error);
        }
    };

    const fetchOldLeads = async () => {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');

        const url = 'https://apil2.wh.tec.br/base_sp/';

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'consultor-name': username
                }
            });

            if (response.ok) {
                const data = await response.json();
                setLeads(data);
                const countVisibleLeads = data.reduce((count, lead) => {
                    return count + (lead.status && lead.status !== '' ? 1 : 0);
                }, 0);
                setVisibleLeads(countVisibleLeads);
            } else {
                console.error('Falha ao buscar leads antigas.');
            }
        } catch (error) {
            console.error('Erro ao tentar buscar leads antigas:', error);
        }
    };

    useEffect(() => {
        const today = new Date();
        fetchLeads(today);
    }, []);

    const handleViewDetails = (lead) => {
        setSelectedLead(lead);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const handleStatusChange = async (leadId, newStatus) => {
        setLeads(prevLeads => prevLeads.map(lead =>
            lead.id === leadId ? { ...lead, status: newStatus } : lead
        ));

        const token = localStorage.getItem('token');

        try {
            const response = await fetch('https://apil2.wh.tec.br/base_sp/status', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    leadId: leadId,
                    status: newStatus
                })
            });

            if (!response.ok) {
                console.error('Falha ao atualizar status:', response.statusText);
            }
        } catch (error) {
            console.error('Erro ao tentar atualizar status:', error);
        }

        if (newStatus === 'enviado') {
            const randomTime = Math.floor(Math.random() * (350 - 200 + 1) + 200) * 1000;
            const endTime = Date.now() + randomTime;
            setTimers((prevTimers) => ({ ...prevTimers, [leadId]: endTime }));

            const intervalId = setInterval(() => {
                const timeLeft = Math.max(0, endTime - Date.now());
                setTimers((prevTimers) => ({
                    ...prevTimers,
                    [leadId]: timeLeft,
                }));

                if (timeLeft === 0) {
                    clearInterval(intervalId);
                    setVisibleLeads(prev => prev + 1);
                }
            }, 1000);
        }
    };

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleSearch = async () => {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');

        const params = new URLSearchParams();
        if (nomeCliente) {
            params.append('nome_cliente', nomeCliente);
        }
        if (statusLead) {
            params.append('status', statusLead);
        }
        if (telefone) {
            params.append('telefone', telefone);
        }

        let url = `https://apil2.wh.tec.br/base_sp/filter?${params.toString()}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'consultor-name': username
                }
            });

            if (response.ok) {
                const data = await response.json();
                setLeads(data);
                setCurrentPage(1);
            } else {
                console.error('Erro ao filtrar leads.');
            }
        } catch (error) {
            console.error('Erro ao tentar filtrar leads:', error);
        }

        handleSearchClose();
    };

    const handleViewModeChange = (event, newView) => {
        if (newView !== null) {
            setViewMode(newView);
        }
    };

    const handleAddStatusOpen = () => setAddStatusOpen(true);
    const handleAddStatusClose = () => {
        setAddStatusOpen(false);
        setNewStatus('');
    };

    const handleAddStatusSubmit = () => {
        const trimmedStatus = newStatus.trim().toLowerCase();
        if (trimmedStatus && !statuses.includes(trimmedStatus)) {
            handleAddStatus(trimmedStatus);
            handleAddStatusClose();
        } else {
            alert('Status inválido ou já existe.');
        }
    };

    const handleSearchOpen = () => setSearchOpen(true);
    const handleSearchClose = () => setSearchOpen(false);

    const indexOfLastLead = currentPage * leadsPerPage;
    const indexOfFirstLead = indexOfLastLead - leadsPerPage;
    const currentLeads = leads.slice(indexOfFirstLead, indexOfLastLead);
    const totalPages = Math.ceil(leads.length / leadsPerPage);

    return (
        <Box
            sx={{
                minHeight: '100vh',
                position: 'relative',
                overflow: 'hidden',
                background: 'linear-gradient(135deg, #1e1e30, #3b3b51, #60607a)',
                backgroundSize: '200% 200%',
                animation: 'gradientShift 20s ease infinite',
                '@keyframes gradientShift': {
                    '0%': { backgroundPosition: '0% 0%' },
                    '50%': { backgroundPosition: '100% 100%' },
                    '100%': { backgroundPosition: '0% 0%' }
                },
                boxShadow: 'inset 0 0 80px rgba(0, 0, 0, 0.5)',
                backdropFilter: 'blur(4px)',
            }}
        >
            <TopMenu onDateChange={(newDate) => fetchLeads(newDate)} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px' }}>
                <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    onChange={handleViewModeChange}
                    aria-label="View Mode"
                >
                    <ToggleButton value="grid" aria-label="Grid View">
                        <ViewModuleIcon />
                    </ToggleButton>
                    <ToggleButton value="kanban" aria-label="Kanban View">
                        <ViewKanbanIcon />
                    </ToggleButton>
                </ToggleButtonGroup>

                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleAddStatusOpen}
                    sx={{
                        backgroundColor: '#1976d2',
                        '&:hover': {
                            backgroundColor: '#115293',
                        },
                        textTransform: 'none'
                    }}
                >
                    Adicionar Status
                </Button>
            </Box>

            <Box sx={{ padding: '20px' }}>
                {viewMode === 'grid' ? (
                    <Grid container spacing={3}>
                        {currentLeads.map((lead, index) => (
                            <Grid item xs={12} sm={6} md={4} key={lead.id}>
                                <LeadCard
                                    lead={lead}
                                    onViewDetails={handleViewDetails}
                                    onStatusChange={handleStatusChange}
                                    disabled={index > visibleLeads || shouldHideNextDayLeads}
                                    statuses={statuses}
                                />
                                {timers[lead.id] > 0 && (
                                    <Typography variant="body2" sx={{ color: '#ff8c00', textAlign: 'center' }}>
                                        Liberando em: {formatTime(timers[lead.id])}
                                    </Typography>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <KanbanBoard
                        leads={leads}
                        onViewDetails={handleViewDetails}
                        onStatusChange={handleStatusChange}
                        statuses={statuses}
                        disabled={shouldHideNextDayLeads}
                    />
                )}

                {leads.length > leadsPerPage && viewMode === 'grid' && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                            sx={{
                                '& .MuiPaginationItem-root': { color: '#fff' },
                                '& .MuiPaginationItem-root.Mui-selected': {
                                    backgroundColor: '#1976d2',
                                    color: '#fff',
                                },
                            }}
                        />
                    </Box>
                )}

                {/* Caso precise do diálogo de notificação, descomente abaixo 
                <Dialog
                    open={notificationOpen}
                    onClose={() => {}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Atualize o Status dos Leads"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Por favor, atualize o status das leads...
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {}} autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                */}

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2
                    }}>
                        <Typography variant="h6" component="h2">
                            Detalhes do Cliente
                        </Typography>
                        {selectedLead && (
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="body1"><strong>Processo:</strong> {selectedLead.processo}</Typography>
                                <Typography variant="body1"><strong>Nome:</strong> {selectedLead.nome_cliente}</Typography>
                                {/* Adicione os demais campos conforme necessário */}
                            </Box>
                        )}
                        <Button variant="contained" onClick={handleClose} sx={{ mt: 2 }}>
                            Fechar
                        </Button>
                    </Box>
                </Modal>

                <Dialog open={addStatusOpen} onClose={handleAddStatusClose}>
                    <DialogTitle>Adicionar Novo Status</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Nome do Status"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={newStatus}
                            onChange={(e) => setNewStatus(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleAddStatusSubmit();
                                }
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAddStatusClose}>Cancelar</Button>
                        <Button onClick={handleAddStatusSubmit} variant="contained" color="primary">
                            Adicionar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Modal open={searchOpen} onClose={handleSearchClose}>
                    <Box sx={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2
                    }}>
                        <Typography variant="h6" component="h2">
                            Filtrar Leads
                        </Typography>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Nome do Cliente"
                            value={nomeCliente}
                            onChange={(e) => setNomeCliente(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Telefone"
                            value={telefone}
                            onChange={(e) => setTelefone(e.target.value)}
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Status</InputLabel>
                            <Select
                                value={statusLead}
                                onChange={(e) => setStatusLead(e.target.value)}
                                label="Status"
                            >
                                <MenuItem value=""><em>Selecione</em></MenuItem>
                                {statuses.map((statusOption) => (
                                    <MenuItem key={statusOption} value={statusOption}>
                                        {statusOption.charAt(0).toUpperCase() + statusOption.slice(1)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Button onClick={handleSearch} variant="contained" color="primary" sx={{ mt: 2 }}>
                            Pesquisar
                        </Button>
                    </Box>
                </Modal>

                <Fab
                    color="primary"
                    aria-label="search"
                    sx={{
                        position: 'fixed',
                        bottom: 16,
                        right: 16,
                    }}
                    onClick={handleSearchOpen}
                >
                    <SearchIcon />
                </Fab>

                <Box sx={{ padding: '10px', textAlign: 'center', color: '#fff', mt: 'auto' }}>
                    <Typography variant="body2">
                        Created by W&H - Group
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default PaginaInicial;
