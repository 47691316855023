import React, { useState, useCallback } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Button,
    IconButton,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Link,
    Dialog,
    Divider,
    Chip,
    Avatar,
    Tooltip,
    Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InfoIcon from '@mui/icons-material/Info';
import ChatIcon from '@mui/icons-material/Chat';
import axios from 'axios';
import MensagensModal from './MensagensModal'; // Importe o MensagensModal

const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: '20px',
    backgroundColor: '#fff',
    color: '#333',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 8px 30px rgba(0,0,0,0.15)',
    },
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5),
    },
}));

const LeadAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: '#1976d2',
    width: theme.spacing(7),
    height: theme.spacing(7),
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        fontSize: '1.5rem',
    },
}));

function LeadCard({ lead, onViewDetails }) {
    const [status, setStatus] = useState(lead.status || '');
    const [showAdditionalPhones, setShowAdditionalPhones] = useState(false);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [openMessagesModal, setOpenMessagesModal] = useState(false);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);

    const toggleAdditionalPhones = () => {
        setShowAdditionalPhones(!showAdditionalPhones);
    };

    const fetchMensagens = useCallback(async (phone) => {
        const token = localStorage.getItem('token');
        const consultorName = localStorage.getItem('username');

        try {
            const response = await axios.get(`https://msg.wh.tec.br/mensagens/55${phone}`, {
                headers: {
                    Authorization: token,
                    'consultor-name': consultorName,
                },
            });

            return response.data.mensagens.map((msg) => ({
                ...msg,
                enviadaPeloUsuario: msg.enviadaPeloUsuario === 'Sim',
                timestamp: msg.timestamp,
            }));
        } catch (error) {
            console.error('Erro ao buscar mensagens:', error);
            return [];
        }
    }, []);

    const handleViewMessages = async (phone) => {
        setSelectedPhoneNumber(phone);
        setOpenMessagesModal(true);
    };

    // Extrai os telefones adicionais
    const additionalPhones = [
        lead.telefone_adicional2,
        lead.telefone_adicional3,
        lead.telefone_adicional4,
        lead.telefone_adicional5,
    ].filter(Boolean); // Remove valores falsy

    return (
        <StyledCard>
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3} sm={2}>
                        <LeadAvatar>{lead.nome_cliente.charAt(0).toUpperCase()}</LeadAvatar>
                    </Grid>
                    <Grid item xs={9} sm={10}>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{ fontWeight: 'bold', color: '#1976d2' }}
                        >
                            {lead.nome_cliente}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" noWrap>
                            Processo: {lead.processo}
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                            <Chip
                                label={lead.status || 'Sem Status'}
                                color={
                                    lead.status === 'enviado'
                                        ? 'primary'
                                        : lead.status === 'negociando'
                                        ? 'warning'
                                        : lead.status === 'nao responde'
                                        ? 'error'
                                        : lead.status === 'venda realizada'
                                        ? 'success'
                                        : 'default'
                                }
                                size="small"
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            Celular: {lead.telefone_principal || 'N/A'}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <Tooltip title="Enviar mensagem via WhatsApp">
                            <IconButton size="small" color="success">
                                <WhatsAppIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Ver mensagens">
                            <IconButton
                                size="small"
                                onClick={() => handleViewMessages(lead.telefone_principal)}
                                sx={{
                                    ml: 1,
                                    color: '#fff',
                                    backgroundColor: '#1976d2',
                                    '&:hover': {
                                        backgroundColor: '#115293',
                                    },
                                }}
                            >
                                <ChatIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth size="small">
                            <InputLabel>Status</InputLabel>
                            <Select
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                label="Status"
                            >
                                <MenuItem value="">
                                    <em>Selecione</em>
                                </MenuItem>
                                <MenuItem value="enviado">Enviado</MenuItem>
                                <MenuItem value="negociando">Negociando</MenuItem>
                                <MenuItem value="nao responde">Não Responde</MenuItem>
                                <MenuItem value="venda realizada">Venda Realizada</MenuItem>
                                <MenuItem value="resolveu com o banco">
                                    Resolveu com o banco
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<InfoIcon />}
                            onClick={() => setOpenDetailsModal(true)}
                            sx={{
                                backgroundColor: '#ff9800',
                                '&:hover': {
                                    backgroundColor: '#fb8c00',
                                },
                            }}
                        >
                            Detalhes
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Link
                            onClick={toggleAdditionalPhones}
                            sx={{
                                cursor: 'pointer',
                                color: '#1976d2',
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            {showAdditionalPhones ? 'Ocultar Telefones' : 'Ver Mais Telefones'}
                        </Link>
                    </Grid>

                    {/* Renderiza os telefones adicionais se showAdditionalPhones for true */}
                    {showAdditionalPhones && additionalPhones.length > 0 && (
                        <>
                            {additionalPhones.map((phone, index) => (
                                <Grid container spacing={2} alignItems="center" key={index} sx={{ mt: 1 }}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                                            Telefone Adicional {index + 2}: {phone}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                                    >
                                        <Tooltip title="Enviar mensagem via WhatsApp">
                                            <IconButton size="small" color="success">
                                                <WhatsAppIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Ver mensagens">
                                            <IconButton
                                                size="small"
                                                onClick={() => handleViewMessages(phone)}
                                                sx={{
                                                    ml: 1,
                                                    color: '#fff',
                                                    backgroundColor: '#1976d2',
                                                    '&:hover': {
                                                        backgroundColor: '#115293',
                                                    },
                                                }}
                                            >
                                                <ChatIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            ))}
                        </>
                    )}
                </Grid>

                <Dialog open={openDetailsModal} onClose={() => setOpenDetailsModal(false)}>
                    <Typography variant="body2" sx={{ padding: 2 }}>Detalhes do Lead</Typography>
                </Dialog>

                <MensagensModal
                    open={openMessagesModal}
                    onClose={() => setOpenMessagesModal(false)}
                    selectedPhoneNumber={selectedPhoneNumber}
                    fetchMensagens={fetchMensagens}
                />
            </CardContent>
        </StyledCard>
    );
}

export default LeadCard;
