import React, { useState, useEffect, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    IconButton,
    Tooltip,
    CircularProgress,
    Box,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MicIcon from '@mui/icons-material/Mic';
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import axios from 'axios';

const MensagensModal = ({ open, onClose, selectedPhoneNumber, fetchMensagens }) => {
    const [novaMensagem, setNovaMensagem] = useState('');
    const [mensagens, setMensagens] = useState([]);
    const [isRecording, setIsRecording] = useState(false);
    const [uploadingAudio, setUploadingAudio] = useState(false);
    const [uploadingMedia, setUploadingMedia] = useState(false);
    const [consultorName, setConsultorName] = useState('');

    const mediaRecorderRef = useRef(null);
    const audioChunks = useRef([]);
    const fileInputRefImage = useRef(null);
    const fileInputRefPdf = useRef(null);

    // **Removido o mapeamento de consultorName para instance**
    // const instanceMap = {
    //     'admin': 'admin_0000',
    //     // Adicione mais mapeamentos se necessário
    // };

    // Recupera o nome do consultor do localStorage quando o componente monta
    useEffect(() => {
        const username = localStorage.getItem('username') || 'Consultor';
        setConsultorName(username);
    }, []);

    // Busca mensagens quando o número de telefone selecionado muda
    useEffect(() => {
        if (selectedPhoneNumber) {
            fetchMensagens(selectedPhoneNumber).then(setMensagens);
        }
    }, [selectedPhoneNumber, fetchMensagens]);

    // Função para enviar uma nova mensagem de texto
    const handleSendNewMessage = async () => {
        if (!novaMensagem.trim() || !selectedPhoneNumber) return;

        try {
            const payload = {
                consultorName, // Incluído consultorName no payload
                number: `55${selectedPhoneNumber}`,
                message: novaMensagem,
            };

            const response = await axios.post(
                'https://wa.solvere.pro/send-message', // URL atualizada
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'apikey': 'Qf82yJixbd2eBM9KZpCpsuhEzKAT9pX7', // Inclua a chave da API
                    },
                }
            );

            if (response.status === 200) {
                setMensagens((prev) => [
                    ...prev,
                    {
                        mensagem: novaMensagem,
                        enviadaPeloUsuario: true,
                        timestamp: new Date().toISOString(),
                        messageType: 'text',
                    },
                ]);
                setNovaMensagem('');
            }
        } catch (error) {
            console.error('Erro ao enviar mensagem:', error.response ? error.response.data : error.message);
            // Opcional: Adicione feedback ao usuário aqui (e.g., exibir um snackbar de erro)
        }
    };

    // Função auxiliar para converter blob para base64
    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
        });
    };

    // Função para enviar áudio gravado como base64
    const sendAudio = async (audioBlob) => {
        if (!selectedPhoneNumber) return;

        setUploadingAudio(true);

        try {
            const base64Audio = await blobToBase64(audioBlob);
            const base64String = base64Audio.split(',')[1]; // Remove o prefixo data:audio/webm;base64,

            const payload = {
                consultorName, // Incluído consultorName no payload
                number: `55${selectedPhoneNumber}`,
                audio: base64String,
            };

            const response = await axios.post(
                'https://wa.solvere.pro/send-audio', // URL atualizada
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'apikey': 'Qf82yJixbd2eBM9KZpCpsuhEzKAT9pX7', // Inclua a chave da API
                    },
                }
            );

            if (response.status === 200) {
                setMensagens((prev) => [
                    ...prev,
                    {
                        mensagem: '[Áudio enviado]',
                        enviadaPeloUsuario: true,
                        timestamp: new Date().toISOString(),
                        messageType: 'audio',
                        audio: base64String, // Adiciona o áudio para reprodução
                    },
                ]);
            }
        } catch (error) {
            console.error('Erro ao enviar áudio:', error.response ? error.response.data : error.message);
            // Opcional: Adicione feedback ao usuário aqui (e.g., exibir um snackbar de erro)
        } finally {
            setUploadingAudio(false);
        }
    };

    // Função para iniciar a gravação de áudio
    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (event) => {
                audioChunks.current.push(event.data);
            };

            mediaRecorderRef.current.onstop = async () => {
                const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
                audioChunks.current = []; // Limpar buffer de áudio
                await sendAudio(audioBlob);
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Erro ao acessar o microfone:', error);
            // Opcional: Adicione feedback ao usuário aqui (e.g., exibir um snackbar de erro)
        }
    };

    // Função para parar a gravação de áudio
    const stopRecording = () => {
        if (mediaRecorderRef.current && isRecording) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    // Função para verificar se a mensagem é uma URL de áudio
    const isAudioUrl = (text) => {
        const audioExtensions = ['.mp3', '.wav', '.ogg', '.oga', '.m4a'];
        try {
            const url = new URL(text);
            return audioExtensions.some(ext => url.pathname.endsWith(ext));
        } catch {
            return false;
        }
    };

    // Função para sanitizar URLs de áudio (substituir @ por %40)
    const sanitizeAudioUrl = (url) => {
        return url.replace(/@/g, '%40');
    };

    // Função para enviar mídia (imagem ou PDF)
    const sendMedia = async (file, mediatype) => {
        if (!selectedPhoneNumber) return;

        setUploadingMedia(true);

        try {
            let mimeType = file.type;
            let media;

            if (file.type.startsWith('image/')) {
                // Para imagens, usamos base64
                media = await blobToBase64(file);
                media = media.split(',')[1]; // Remove o prefixo data:image/png;base64,
            } else if (file.type === 'application/pdf') {
                // Para PDFs, usamos base64
                media = await blobToBase64(file);
                media = media.split(',')[1]; // Remove o prefixo data:application/pdf;base64,
            } else {
                throw new Error('Tipo de mídia não suportado');
            }

            const payload = {
                consultorName, // Incluído consultorName no payload
                number: `55${selectedPhoneNumber}`,
                mediatype: mediatype, // 'image' ou 'document'
                mimetype: mimeType,
                caption: '', // Opcional: Adicione uma legenda se desejar
                media: media, // Base64
                fileName: file.name,
            };

            const response = await axios.post(
                `https://wa.solvere.pro/send-media`, // URL atualizada
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'apikey': 'Qf82yJixbd2eBM9KZpCpsuhEzKAT9pX7', // Inclua a chave da API
                    },
                }
            );

            if (response.status === 200) {
                setMensagens((prev) => [
                    ...prev,
                    {
                        mensagem: payload.caption || `[${mediatype.charAt(0).toUpperCase() + mediatype.slice(1)} enviado]`,
                        enviadaPeloUsuario: true,
                        timestamp: new Date().toISOString(),
                        messageType: mediatype === 'image' ? 'image' : 'document',
                        media: media, // Adiciona a mídia para exibição
                        mimeType: mimeType,
                        fileName: file.name,
                    },
                ]);
            }
        } catch (error) {
            console.error('Erro ao enviar mídia:', error.response ? error.response.data : error.message);
            // Opcional: Adicione feedback ao usuário aqui (e.g., exibir um snackbar de erro)
        } finally {
            setUploadingMedia(false);
        }
    };

    // Função para lidar com a seleção de imagens
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            sendMedia(file, 'image');
        }
    };

    // Função para lidar com a seleção de PDFs
    const handlePdfUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            sendMedia(file, 'document');
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen
        >
            <DialogTitle>
                {consultorName
                    ? `Conversa entre ${consultorName} e ${selectedPhoneNumber}`
                    : `Conversa com ${selectedPhoneNumber}`}
                <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#eae6df',
                    padding: 0,
                    height: '100vh', // Ocupa toda a altura da viewport
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        overflowY: 'auto',
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                    }}
                >
                    {mensagens.map((msg, index) => {
                        const mensagemIsAudio = isAudioUrl(msg.mensagem);

                        // Determina a URL de áudio correta
                        let audioSrc = null;
                        if (msg.messageType === 'audio') {
                            // Se for um áudio enviado, pode ter `audioUrl` ou `audio` (base64)
                            if (msg.audioUrl) {
                                audioSrc = sanitizeAudioUrl(msg.audioUrl);
                            } else if (msg.audio) {
                                audioSrc = `data:audio/webm;base64,${msg.audio}`;
                            }
                        } else if (mensagemIsAudio) {
                            // Se a mensagem for uma URL de áudio recebida
                            audioSrc = sanitizeAudioUrl(msg.mensagem);
                        }

                        // Determina se a mensagem é uma imagem ou documento
                        const isImage = msg.messageType === 'image';
                        const isDocument = msg.messageType === 'document';

                        return (
                            <Box
                                key={index}
                                sx={{
                                    alignSelf: msg.enviadaPeloUsuario ? 'flex-end' : 'flex-start',
                                    maxWidth: '80%',
                                    backgroundColor: msg.enviadaPeloUsuario ? '#d9fdd3' : '#ffffff',
                                    borderRadius: '10px',
                                    padding: '8px 12px',
                                    boxShadow: '0px 1px 2px rgba(0,0,0,0.2)',
                                    fontSize: '14px',
                                    color: '#111',
                                }}
                            >
                                {isImage && msg.media ? (
                                    <img
                                        src={`data:${msg.mimeType};base64,${msg.media}`}
                                        alt={msg.fileName}
                                        style={{ maxWidth: '100%', borderRadius: '5px' }}
                                    />
                                ) : isDocument && msg.media ? (
                                    <a
                                        href={`data:${msg.mimeType};base64,${msg.media}`}
                                        download={msg.fileName}
                                        style={{ textDecoration: 'none', color: '#1976d2' }}
                                    >
                                        📄 {msg.fileName}
                                    </a>
                                ) : audioSrc ? (
                                    <audio controls src={audioSrc} />
                                ) : (
                                    msg.mensagem
                                )}
                                {msg.caption && <Box sx={{ fontStyle: 'italic', marginTop: '4px' }}>{msg.caption}</Box>}
                                <Box sx={{ fontSize: '10px', textAlign: 'right', marginTop: '4px', color: '#888' }}>
                                    {new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px',
                        backgroundColor: '#ffffff',
                        borderTop: '1px solid #ddd',
                    }}
                >
                    {/* Botão para enviar imagem */}
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        ref={fileInputRefImage}
                        onChange={handleImageUpload}
                    />
                    <Tooltip title="Enviar imagem">
                        <IconButton
                            color="primary"
                            onClick={() => fileInputRefImage.current && fileInputRefImage.current.click()}
                            disabled={uploadingAudio || uploadingMedia}
                        >
                            <ImageIcon />
                        </IconButton>
                    </Tooltip>

                    {/* Botão para enviar PDF */}
                    <input
                        type="file"
                        accept="application/pdf"
                        style={{ display: 'none' }}
                        ref={fileInputRefPdf}
                        onChange={handlePdfUpload}
                    />
                    <Tooltip title="Enviar PDF">
                        <IconButton
                            color="primary"
                            onClick={() => fileInputRefPdf.current && fileInputRefPdf.current.click()}
                            disabled={uploadingAudio || uploadingMedia}
                        >
                            <PictureAsPdfIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={isRecording ? 'Parar gravação' : 'Gravar áudio'}>
                        <IconButton
                            color={isRecording ? 'secondary' : 'primary'}
                            onClick={isRecording ? stopRecording : startRecording}
                            disabled={uploadingAudio || uploadingMedia}
                        >
                            <MicIcon />
                        </IconButton>
                    </Tooltip>
                    <TextField
                        fullWidth
                        placeholder="Digite sua mensagem"
                        value={novaMensagem}
                        onChange={(e) => setNovaMensagem(e.target.value)}
                        sx={{ marginX: '8px' }}
                        disabled={uploadingAudio || uploadingMedia || isRecording}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleSendNewMessage();
                            }
                        }}
                    />
                    <Tooltip title="Enviar mensagem">
                        <IconButton
                            color="primary"
                            onClick={handleSendNewMessage}
                            disabled={!novaMensagem.trim() || uploadingAudio || uploadingMedia || isRecording}
                        >
                            <SendIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                {(uploadingAudio || uploadingMedia) && (
                    <Box sx={{ textAlign: 'center', padding: '8px' }}>
                        <CircularProgress size={24} />
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );

};

export default MensagensModal;
