import React from 'react';
import { Box, Typography } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LeadCard from './LeadCard';

function KanbanBoard({ leads, onViewDetails, onStatusChange, statuses, disabled }) {
    // Organize as leads por status
    const leadsByStatus = statuses.reduce((acc, status) => {
        acc[status] = leads.filter(lead => lead.status === status);
        return acc;
    }, {});

    // Função chamada quando uma lead é arrastada para uma nova coluna
    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result;

        // Se não houver destino, não faça nada
        if (!destination) return;

        // Se o destino for o mesmo que a origem, não faça nada
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        // Encontrar a lead arrastada
        const lead = leads.find(l => l.id.toString() === draggableId);
        if (!lead) return;

        // Atualizar o status da lead
        onStatusChange(lead.id, destination.droppableId);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box
                sx={{
                    display: 'flex',
                    gap: '24px',
                    overflowX: 'auto',
                    padding: '16px',
                }}
            >
                {statuses.map((status) => (
                    <Box
                        key={status}
                        sx={{
                            backgroundColor: '#ffffff',
                            borderRadius: '12px',
                            padding: '16px',
                            minWidth: '350px',
                            maxWidth: '350px',
                            flex: '0 0 auto',
                            height: '80vh',
                            overflowY: 'auto',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            transition: 'transform 0.2s',
                            '&:hover': {
                                transform: 'scale(1.02)',
                            },
                        }}
                    >
                        <Typography
                            variant="h6"
                            align="center"
                            gutterBottom
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '1.1rem',
                                color: '#3f51b5',
                                textTransform: 'capitalize',
                            }}
                        >
                            {status}
                        </Typography>
                        <Droppable droppableId={status}>
                            {(provided, snapshot) => (
                                <Box
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    sx={{
                                        minHeight: '100px',
                                        backgroundColor: snapshot.isDraggingOver
                                            ? '#e3f2fd'
                                            : '#ffffff',
                                        padding: '8px',
                                        borderRadius: '8px',
                                        transition: 'background-color 0.3s',
                                    }}
                                >
                                    {leadsByStatus[status].map((lead, index) => (
                                        <Draggable key={lead.id.toString()} draggableId={lead.id.toString()} index={index}>
                                            {(provided, snapshot) => (
                                                <Box
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    sx={{
                                                        marginBottom: '12px',
                                                        borderRadius: '8px',
                                                        boxShadow: snapshot.isDragging
                                                            ? '0 8px 16px rgba(0, 0, 0, 0.2)'
                                                            : '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                        transition: 'box-shadow 0.2s, opacity 0.2s',
                                                        opacity: snapshot.isDragging ? 0.9 : 1,
                                                    }}
                                                >
                                                    <LeadCard
                                                        lead={lead}
                                                        onViewDetails={onViewDetails}
                                                        onStatusChange={onStatusChange}
                                                        disabled={disabled}
                                                        statuses={statuses}
                                                    />
                                                </Box>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Box>
                            )}
                        </Droppable>
                    </Box>
                ))}
            </Box>
        </DragDropContext>
    );
}

export default KanbanBoard;
